<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div class="fz-18">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span class="fw-600 fz-22">订单信息</span>
        </div>
      </template>
      <el-row>
        <el-col :xs="24" :sm="10" class="overflow-hidden">
          <div class="d-flex align-center pb-10">
            <span>订单编号:</span>
            <span class="ml-20">{{ state.orderInfo.orderNo }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>下单人姓名:</span>
            <span class="ml-20">{{ state.orderInfo.name }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>下单人手机号:</span>
            <span class="ml-20">{{ state.orderInfo.mobile }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>支付方式:</span>
            <span class="ml-20">
              {{ payStyle[state.orderInfo.payType] || '暂无' }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>订单金额:</span>
            <span class="ml-20">{{ state.orderInfo.orderPrice }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>优惠金额:</span>
            <span class="ml-20">暂无</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>实付金额:</span>
            <span class="ml-20">{{ state.orderInfo.orderPrice }}</span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="10">
          <div class="d-flex align-center pb-10">
            <span>订单状态:</span>
            <span class="ml-20 d-block">
              {{ filterOrderStatus[state.orderInfo.status] }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>下单时间:</span>
            <span class="ml-20">
              {{ filterTime(state.orderInfo.orderTime) }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>支付时间:</span>
            <span class="ml-20">{{ filterTime(state.orderInfo.payTime) }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>申请退款:</span>
            <span class="ml-20">
              {{ filterTime(state.orderInfo.refundApplyTime) }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>退款时间:</span>
            <span class="ml-20">
              {{ filterTime(state.orderInfo.refundTime) }}
            </span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <template #header>
        <div class="card-header">
          <span class="fw-600 fz-22">门票信息</span>
        </div>
      </template>
      <el-row>
        <el-col :xs="24" :sm="10" class="overflow-hidden">
          <div class="d-flex align-center pb-10">
            <span>门票名称:</span>
            <span class="ml-20">{{ state.orderInfo.ticketName }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票原价:</span>
            <span class="ml-20">{{ state.orderInfo.ticketPrice }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票现价:</span>
            <span class="ml-20">{{ state.orderInfo.ticketSellingPrice }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票数量:</span>
            <span class="ml-20">{{ state.orderInfo.count }}</span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票出售时间:</span>
            <span class="ml-20">
              {{ filterTime(state.orderInfo.buyStartTime) }}~
              {{ filterTime(state.orderInfo.buyEndTime) }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票入场时间:</span>
            <span class="ml-20">
              {{ filterTime(state.orderInfo.entranceStartTime) }}~
              {{ filterTime(state.orderInfo.entranceEndTime) }}
            </span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="10">
          <div class="d-flex align-center pb-10">
            <span>门票类型:</span>
            <span class="ml-20 d-block">
              {{ filterTicketsRelation[state.orderInfo.ticketType] }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>门票关联:</span>
            <span class="ml-20">
              {{ state.orderInfo.meetingName || '暂无' }}
            </span>
          </div>
          <div class="d-flex align-center pb-10">
            <span>所属展会:</span>
            <span class="ml-20">
              {{ state.orderInfo.meetingName || '暂无' }}
            </span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <template #header>
        <div class="card-header">
          <span class="fw-600 fz-22">门票使用记录</span>
        </div>
      </template>
      <vab-query-form>
        <vab-query-form-top-panel :span="24">
          <el-table
            :data="state.orderInfo.userTicketUseLogList"
            style="width: 100%"
          >
            <el-table-column
              label="门票持有人姓名"
              prop="name"
              align="center"
            />
            <el-table-column
              label="持有人手机号"
              prop="mobile"
              align="center"
            />
            <el-table-column label="身份证号码" prop="idNo" align="center" />
            <el-table-column label="门票获得时间" prop="getTime" align="center">
              <template #default="{ row }">
                {{ filterTime(row.getTime) }}
              </template>
            </el-table-column>
            <el-table-column label="门票使用时间" prop="useTime" align="center">
              <template #default="{ row }">
                {{ filterTime(row.useTime) }}
              </template>
            </el-table-column>
            <el-table-column label="门票状态" prop="status" align="center">
              <template #default="{ row }">
                {{ row.status ? filterTicketStatus[row.status] : '暂无' }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              show-overflow-tooltip
              width="185"
              align="center"
            >
              <template #default="{ row }">
                <el-button
                  v-if="
                    filterTicketStatus[row.status] == '已使用' ||
                    '可使用' ||
                    '待使用'
                  "
                  type="primary"
                  @click="handlerChangeStatus(row)"
                >
                  更改状态
                </el-button>
                <div v-else>/</div>
              </template>
            </el-table-column>
          </el-table>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-card>
    <div class="d-flex a-center mt-20 justify-end pb-20">
      <el-button
        v-if="state.orderInfo.status == 'REFUND_AUDIT'"
        type="warning"
        class="mr-10"
        @click="handleARefundAudit"
      >
        退款审核
      </el-button>
      <el-button plain @click="handleGoBack" size="medium">返回</el-button>
    </div>
    <!-- 退款审核 -->
    <refundAudit ref="refRefundAudit" @noticeRefresh="getData"></refundAudit>
  </div>
</template>
<script>
  export default { name: 'orderDetail' }
  const payStyle = {
    wxPay: '微信支付',
  }
</script>
<script setup>
  import { reactive, ref, onActivated } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { orderDetail, changeTicketsStatus } from '@/api/exhibitionManage'
  import { parseTime } from '@/utils/index'
  import { useComponent } from './components/index'
  import { debounce } from '@/utils/debounce'
  // 引入组件
  const { refundAudit } = useComponent()
  const router = useRouter()
  const route = useRoute()
  const state = reactive({
    orderNo: '',
    orderInfo: {},
  })
  // 门票类型
  const filterTicketsRelation = {
    EXHIBITION: '展会类型',
    FORUM: '论坛类型',
    null: '暂无',
  }
  // 门票的状态
  const filterTicketStatus = {
    null: '暂无',
    USED: '已使用',
    WAIT_USED: '待使用',
    EXPIRED: '已过期',
    CLOSED: '已关闭',
    NOT_START: '未开始',
    CAN_USED: '可使用',
  }
  // 订单状态
  const filterOrderStatus = {
    PAY_WAIT: '待支付',
    PAY_PART: '部分支付',
    PAY_OVER: '已支付',
    REFUND_PART: '部分退款',
    REFUND_APPLY: '退款申请',
    REFUND_AUDIT: '退款审批中',
    REFUND: '全部退款',
    CLOSED: '已关闭',
    null: '暂无',
  }
  // 筛选时间
  const filterTime = (val) => {
    return val ? parseTime(val) : '暂无'
  }
  // 门票信息
  const ticketsInfo = reactive({})
  // 门票使用记录
  const loading = ref(false) // 列表动画加载
  // 退款审核的弹窗
  const refRefundAudit = ref(null)
  // 获取初始化数据
  const getData = async () => {
    loading.value = true
    const { data } = await orderDetail(state.orderNo)
    state.orderInfo = data
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 更改状态
  const handlerChangeStatus = debounce(async (row) => {
    // if (filterTicketStatus[row.status] !== ('已使用' || '可使用')) {
    const resp = await changeTicketsStatus(
      state.orderInfo.orderNo,
      state.orderInfo.ticketCode
    )
    getData()
    // }
  })
  // 退款审核
  const handleARefundAudit = debounce(() => {
    refRefundAudit.value.handleOpen(state.orderInfo.orderNo)
  })
  // 返回
  const handleGoBack = () => {
    router.go(-1)
  }
  onActivated(() => {
    state.orderNo = route.query.orderNo || ''
    state.orderNo && getData()
  })
</script>
